import React from 'react';
import { useTranslation } from 'react-i18next';

import { Layout } from 'antd';
import AntdResult from 'antd/lib/result';

import cn from 'classnames';

import Grid from 'components/grid';
import { Text } from 'components/typography';
import DashboardFooter from 'layout/dashboard-footer';
import DashboardHeader from 'layout/dashboard-header';
import MaintenanceIcon from 'resources/svg/maintenance.svg';

import s from './s.module.scss';

const Maintenance = () => {
  const { t } = useTranslation();

  return (
    <Layout className={s.layout}>
      <DashboardHeader />
      <AntdResult
        className={s.container}
        icon={
          <div className={s.icon}>
            <img src={MaintenanceIcon} alt="maintenance" />
          </div>
        }
        title={
          <Grid flow="col" className={cn('mt-16', s.title)}>
            <Grid flow="row">
              <Text type="h1" color="primary">
                {t('maintenance_body_1')}
                <a
                  href="https://twitter.com/OttoBlockchain?s=20&t=a0EEZCu3KYRVPx8rfpp14w"
                  className={s.link}
                >
                  Twitter
                </a>
                {t('maintenance_body_2')}
                <a href="https://discord.gg/zng3qMqWT9" className={s.link}>
                  Discord
                </a>
                {t('maintenance_body_3')}
              </Text>
            </Grid>
          </Grid>
        }
      />
      <DashboardFooter />
    </Layout>
  );
};

export default Maintenance;

import { useTranslation } from 'react-i18next';

import { Layout, Space } from 'antd';

import clxs from 'classnames';

import { Text } from 'components/typography';
import DiscordSvg from 'resources/svg/discord.svg';
import MediumSvg from 'resources/svg/medium.svg';
import TwitterSvg from 'resources/svg/twitter.svg';
import YoutubeSvg from 'resources/svg/youtube.svg';

import s from './s.module.scss';

const { Footer } = Layout;

const DashboardFooter = () => {
  const { t } = useTranslation();
  return (
    <Footer className={s.footer}>
      <Text type="p2" color="white" weight="semibold">
        Join us on social media
      </Text>

      <Space className={s.icons} direction="horizontal" size={'large'}>
        <a href="https://twitter.com/OttoBlockchain" target="_blank" rel="noopener noreferrer">
          <img src={TwitterSvg} alt="twitter" />
        </a>
        <a href="https://discord.gg/zng3qMqWT9" target="_blank" rel="noopener noreferrer">
          <img src={DiscordSvg} alt="discord" />
        </a>
        <a
          href="https://www.youtube.com/@ottoblockchain2986"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={YoutubeSvg} alt="facebook" />
        </a>
        <a href="https://ottoblockchain.medium.com/" target="_blank" rel="noopener noreferrer">
          <img src={MediumSvg} alt="medium" />
        </a>
      </Space>

      <Space className={s.links} direction="horizontal" size={'large'}>
        <a
          className={clxs(s.text, s.link)}
          href="https://youtu.be/o4vayP5LxO8"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('footer_link_how_it_works')}
        </a>
        <a
          className={clxs(s.text, s.link)}
          href="https://www.ottoblockchain.com/token"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('footer_link_token_info')}
        </a>
        <a
          className={clxs(s.text, s.link)}
          href="https://www.ottoblockchain.com/our-vision"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('footer_link_about_us')}
        </a>
        <a
          className={clxs(s.text, s.link)}
          href="https://twitter.com/OttoBlockchain"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('footer_link_social_media')}
        </a>
        <a
          className={clxs(s.text, s.link)}
          href="./terms.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('footer_link_terms_of_service')}
        </a>
        <a
          className={clxs(s.text, s.link)}
          href="./privacy.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('footer_link_privacy_policy')}
        </a>
      </Space>

      <Text type="p2" color="white" className="mt-16">
        {t('footer_copyright')}
      </Text>
    </Footer>
  );
};

export default DashboardFooter;

import { Dispatch, FC, ReactNode, SetStateAction, createContext, useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

import { Spin } from 'antd';

import { getProfile, saveProfile } from 'utils/fetchRequest';

import useAuthContext from './auth-provider';

interface IFeedbackStatus {
  status: 'PENDING' | 'ERROR' | 'DONE';
  errorCode?: number;
}

export enum IDVerificationStatus {
  APPROVED_VERIFIED = 'APPROVED_VERIFIED',
}

export enum IDVerifiedStatus {
  Verified = 'ID Verified',
  Unverified = 'ID Unverified',
}

export enum KycRewardStatus {
  UNCLAIMED = 'unclaimed',
  CLAIMED = 'claimed',
  PENDING = 'pending',
  PROCESSING = 'processing',
  SUCCESS = 'success',
  FAILED = 'failed',
}

export type SimilarityType = 'MATCH' | 'NO_MATCH' | 'NOT_POSSIBLE';
export type AMLSearchStatus = 'DONE' | 'NOT_DONE' | 'ERROR';
export type ManualStatus = 'APPROVED' | 'DENIED';
export interface IdentityVerification {
  similarity: SimilarityType;
  validity: boolean;
  reason?: string;
}

export interface AMLCheckResult {
  searchStatus: AMLSearchStatus;
  searchResults: string;
}

export interface AMLManualCheckResult {
  status: ManualStatus;
  reason: string;
}

export interface IdVerificationResult {
  idFirstName?: string;
  idLastName?: string;
  verificationStatus?: IDVerificationStatus;
  idAddress?: string;
  identityVerification: string;
}

export interface DocManualVerificationStatus {
  status: ManualStatus;
}

export interface DvDocumentTransaction {
  timestamp: string;
  scanReference: string;
}

export interface KycReward {
  referenceId: string;
  status: KycRewardStatus;
  rewardAmount: number;
  blockHash?: string;
  extrinsicIndex?: string;
  extrinsicHash?: string;
}

export interface IProfile {
  depositTargets?: any;
  authId: string;
  email: string;
  nickname?: string;
  countryOfResidence?: string;
  phone?: string;
  prefix?: string;
  suffix?: string;
  idVerificationResult?: IdVerificationResult;
  idVerificationStatus?: IFeedbackStatus;
  amlCheckResult?: AMLCheckResult;
  amlManualCheckResult?: AMLManualCheckResult;
  docVerificationResult?: any;
  docTransaction?: any;
  docVerificationStatus?: IFeedbackStatus;
  ethWalletAddress?: string;
  ethWalletAddresses?: string[];
  ottoWalletAddress?: string;
  xrplWalletAddresses?: string[];
  nftCID?: string;
  docManualVerificationStatus?: DocManualVerificationStatus;
  dvDocumentTransaction?: DvDocumentTransaction;
  isOttoWalletSigned?: boolean;
  ottoWalletStatus: OttoWalletStatus;
  kycReward: KycReward;
}

export enum OttoWalletStatus {
  bind_request = 'bind_request',
  unbind_request = 'unbind_request',
  binding = 'binding',
  unbinding = 'unbinding',
  bound = 'bound',
  unbound = 'unbound',
}

export type ProfileContextType = {
  profile: IProfile | undefined;
  setProfile: Dispatch<SetStateAction<IProfile | undefined>>;
  setReloadProfile: (reloadProfile: boolean) => void;
};

const Context = createContext<ProfileContextType>({
  profile: undefined,
  setProfile: () => null,
  setReloadProfile: () => null,
});

export function useProfileContext(): ProfileContextType {
  return useContext(Context);
}

export const hasIDVerified = (profile: IProfile | undefined): boolean => {
  const identityVerification: IdentityVerification = JSON.parse(
    profile?.idVerificationResult?.identityVerification ?? '{}'
  );

  return (
    profile?.idVerificationResult?.verificationStatus === IDVerificationStatus.APPROVED_VERIFIED &&
    identityVerification?.similarity === 'MATCH' &&
    profile?.amlCheckResult?.searchStatus === 'DONE' &&
    parseInt(profile?.amlCheckResult?.searchResults) <= 0
  );
};

const fetchProfile = async (token: any, user: any) => {
  const { response: userProfile, error } = await getProfile(token);

  if (!error) return userProfile;

  if (user?.sub) {
    const payload = {
      authId: user?.sub,
    };
    await saveProfile(token, payload);

    return payload;
  }
};

const phoneRegex = /(?<prefix>[\\+]?\d{1,4})\s(?<suffix>[\s\d]*)$/;

const formatPhoneNumber = (phone: string) => {
  const groups = phoneRegex.exec(phone)?.groups;
  return {
    prefix: groups?.prefix,
    suffix: groups?.suffix,
  };
};

const useUserProfile = () => {
  const { accessToken, user } = useAuthContext();
  const [profile, setProfile] = useState<IProfile | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [reloadProfile, setReloadProfile] = useState<boolean>(false);

  useEffect(() => {
    async function fetchProfileOrCreate() {
      setLoading(true);
      const userProfile = await fetchProfile(accessToken, user);
      setProfile({
        ...userProfile,
        ...formatPhoneNumber(userProfile ? userProfile.phone : ''),
      });
      setLoading(false);
    }

    if (!profile || reloadProfile) {
      fetchProfileOrCreate();
      setReloadProfile(false);
    }
  }, [accessToken, reloadProfile]);

  return { loading, profile, setProfile, setReloadProfile };
};

const ProfileProvider: FC<{ children?: ReactNode }> = (props) => {
  const { children } = props;

  const { loading, profile, setProfile, setReloadProfile } = useUserProfile();

  const value: ProfileContextType = {
    profile,
    setProfile,
    setReloadProfile,
  };

  return (
    <Context.Provider value={value}>{loading ? <Spin size="large" /> : children}</Context.Provider>
  );
};

export default ProfileProvider;
